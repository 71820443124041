/** @jsx jsx */
import React from "react"
import { Box, Text, Grid, jsx } from "theme-ui"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import ContentSection from "../components/ContentSection"
import EmptyCell from "../components/EmptyCell"
import { keyframes } from "@emotion/core"
import PageHeader from "../components/PageHeader"

// icons
import StudentIcon from "../../images/student.svg"
import TestSeriesIcon from "../../images/testSeries.svg"
import OrientationIcon from "../../images/orientatation.svg"
import InterviewGuidanceIcon from "../../images/interviewGuidance.svg"
import OnlineClassIcon from "../../images/onlineCourse.svg"
import LibraryIcon from "../../images/library.svg"
import TeacherIcon from "../../images/teacher.svg"
import PostelIcon from "../../images/postel.svg"
import StudyFromHomeIcon from "../../images/studyFromHome.svg"
import TrustedIcon from "../../images/trusted.svg"
import InfrastructureIcon from "../../images/school.svg"
import RedBubbleIcon from "../../images/redBubble.svg"
import BlueBubbleIcon from "../../images/blueBubble.svg"
import GreenBubbleIcon from "../../images/greenBubble.svg"
import PinkBubbleIcon from "../../images/pinkBubble.svg"
import YellowBubbleIcon from "../../images/yellowBubble.svg"

const float = keyframes`
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
`

interface WhyCardProps {
  icon: string
  header: string
  description: string
  bgIcon: string
}

const WhyCard = (props: WhyCardProps): JSX.Element => {
  const { bgIcon, icon, header, description } = props

  return (
    <Box
      sx={{
        textAlign: "center",
        position: "relative",
      }}
      py={5}
    >
      {/* Bg image */}
      <img
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "400px",
          zIndex: -1,
        }}
        src={bgIcon}
      />
      <img
        src={icon}
        sx={{
          maxWidth: "120px",
          animation: `${float} 6s ease-in-out infinite`,
        }}
        alt={header}
      />
      <Text sx={{fontSize:[1,2]}} >{header}</Text>
      <Text sx={{ maxWidth: "300px", mx: "auto",fontSize:[0,1] }} mt={2} variant="secondary">
        {description}
      </Text>
    </Box>
  )
}

const IndexPage = ({ data }) => (
  <Box>
    <SEO title="Why Sarkar IAS ACADEMY?" />
    <Layout>
      <PageHeader>
        <Text sx={{ fontSize: [1, 3] ,fontStyle:"italic"}}>
          SARKAR IAS ACADEMY is one of the most Trusted, Technically
          well-equipped institutions in Chennai for coaching UPSC,TNPSC, CIVIL
          ASPIRANTS WITH FREE INTERVIEW AND HIGHLY-QUALITY EDUCATIONAL RESOURCES
          CAN BE PRESENTED AS METICULOUSLY CRAFTED STUDY MATERIALS.
        </Text>
      </PageHeader>
      <ContentSection
        header="Why Sarkar IAS ACADEMY?"
        noHeaderBorder={true}
        body={
          <Grid
            mt={-3}
            sx={{ maxWidth: "1200px", mx: "auto", justifyItems: "center" }}
            columns={[1, null, 2, 3]}
            gap={[5, null, 3, 0]}
          >
            <Box data-sal="slide-right">
              <WhyCard
                icon={StudentIcon}
                bgIcon={BlueBubbleIcon}
                header="Achiever Student Interaction"
                description="Engaging with accomplished students periodically, seeking insights from past exam 
toppers , can unveil effective strategies for success in the educational journey"
              />
            </Box>
            <EmptyCell />
            <Box data-sal="zoom-in">
              <WhyCard
                icon={TestSeriesIcon}
                bgIcon={PinkBubbleIcon}
                header="Test Series"
                description="Consistent administration of  mock tests, mirroring the actual exam pattern, aids
students in accessing their preparation status and refining their strategies."
              />
            </Box>
            <EmptyCell />
            <Box data-sal="fade">
              <WhyCard
                icon={OnlineClassIcon}
                bgIcon={YellowBubbleIcon}
                header="Online Classes"
                description="Engaging in daily live classes – A virtual alternative eliminating the need for physical 
attendance."
              />
            </Box>
            <EmptyCell />
            <Box data-sal="flip-down">
              <WhyCard
                icon={OrientationIcon}
                bgIcon={GreenBubbleIcon}
                header="Orientation & Guest Lectures"
                description="We welcome distinguished individuals to provide education and inspiration 
    through orientation sessions and guest lectures for our students"
              />
            </Box>
            <EmptyCell />
            <Box data-sal="slide-left">
              <WhyCard
                icon={InterviewGuidanceIcon}
                bgIcon={RedBubbleIcon}
                header="Free Interview Guidance"
                description="Conducting complimentary mock interviews aids students in UPSC preparation, 
offering valuable practice for their actual interviews"
              />
            </Box>
            <EmptyCell />
            <Box data-sal="flip-up">
              <WhyCard
                bgIcon={PinkBubbleIcon}
                icon={TrustedIcon}
                header="Most Trusted Academy"
                description="A widely relied-upon academy, endorsed by students nationwide. Our reviews stand 
    as a testament to this trust."
              />
            </Box>
            <EmptyCell />
            <Box data-sal="zoom-out">
              <WhyCard
                bgIcon={BlueBubbleIcon}
                icon={LibraryIcon}
                header="Digital Library"
                description="Introducing the pioneering digital library encompassing all essential study materials 
for exam preparation.  Accessing study resources is now just a click away.."
              />
            </Box>
            <EmptyCell />
            <Box data-sal="flip-down">
              <WhyCard
                bgIcon={YellowBubbleIcon}
                icon={TeacherIcon}
                header="Interaction with Faculty"
                description="Regular doubt resolution sessions with faculty and motivational talks ensure 
sustained students encouragement throughout their academic journey."
              />
            </Box>
            <EmptyCell />
            <Box data-sal="fade">
              <WhyCard
                bgIcon={GreenBubbleIcon}
                icon={PostelIcon}
                header="Postel"
                description="If you are unable to attend classes, our postal offers study materials, allowing
you to prepare conveniently from any location."
              />
            </Box>
            <EmptyCell />
            <Box data-sal="flip-right">
              <WhyCard
                bgIcon={YellowBubbleIcon}
                icon={StudyFromHomeIcon}
                header="Study From Home"
                description="Always be flexible in learning, study from the comfort of your home with online 
classes, or attend in person classroom sessions."
              />
            </Box>
            <EmptyCell />
            <Box data-sal="flip-left">
              <WhyCard
                bgIcon={BlueBubbleIcon}
                icon={InfrastructureIcon}
                header="Best Infrastructure"
                description="Meticulously designed and brightly illuminated facilities to ensure an exceptional 
learning experience for our students"
              />
            </Box>
          </Grid>
        }
      />
    </Layout>
  </Box>
)

export default IndexPage
